import React from 'react'
import style from './index.module.less'
import {
    pioneers_meet_1,
    pioneers_meet_2,
    pioneers_meet_3,
    pioneers_meet_4,
    pioneers_meet_5,
    pioneers_meet_6,
    pioneers_meet_7,
    pioneers_meet_8,
    pioneers_meet_9,
    yt_icon,
    x_icon,
    reddit_icon,
    tiktok_icon,
    bsky_icon
} from '@/images/pioneers/index'

const Meet = () => {
    const meet_arr=[
        {
            name:'Katsuya',
            img:pioneers_meet_1,
            yt:'https://www.youtube.com/@Katsuyamaki'
        },
        {
            name:'Kola',
            img:pioneers_meet_2,
            yt:"https://www.youtube.com/@KingKola_/videos"
        },
        {
            name:"ketley2aug",
            img:pioneers_meet_3,
            x:"https://www.x.com/ketley_2aug",
            reddit:"https://www.reddit.com/user/No_Awareness_4626/"
        },{
            name:"eminus",
            img:pioneers_meet_4,
            yt:"https://www.youtube.com/ErikHartley",
            x:"https://www.twitter.com/eminus"
        },
        {
            name:'justinseans',
            img:pioneers_meet_5,
            yt:"https://www.youtube.com/@justinseans"
        },
        {
            name:"Getting Things Dun",
            img:pioneers_meet_6,
            yt:"https://www.youtube.com/channel/UCPeIDBdAGBtJ3cKY3tFh9JA",
            x:"https://x.com/jdunrrp"
        },
        {
          name: 'Sensasian',
          img: pioneers_meet_7,
          tiktok: "https://www.tiktok.com/@sensasianone?_t=ZT-8t4mI5NtDgo&_r=1",
          yt: "https://www.youtube.com/@sensasianone_battlestation2433/videos"
        },
        {
          name: 'JoyReignVR',
          img: pioneers_meet_8,
          yt: "https://www.youtube.com/@GettingThingsDun",
          x: "https://x.com/JoyReignVr"
        },
        {
          name: 'UGEplex',
          img: pioneers_meet_9,
          bsky: "https://ugeplex.bsky.social/",
          x: "https://twitter.com/ugeplex"
        }
    ]
    return(
        <div className={style.box}>
            <div className={style.content}>
                {
                    meet_arr.map((item,index)=>(
                        <div className={style.contentItem} key={`pioneers_meet${index}`}>
                            <img src={item.img} className={style.contentItemImg} />
                            <div className={style.name}>
                                {item.name}
                            </div>
                            <div className={style.link}>
                                {!!item.tiktok&&<img src={tiktok_icon} onClick={()=>window.open(item.tiktok)}/>}
                                {!!item.x&&<img src={x_icon} onClick={()=>window.open(item.x)}/>}
                                {!!item.yt&&<img src={yt_icon} onClick={()=>window.open(item.yt)}/>}
                                {!!item.reddit&&<img src={reddit_icon} onClick={()=>window.open(item.reddit)}/>}
                                {!!item.bsky && <button onClick={()=>window.open(item.bsky)}><img alt={item.bsky} src={bsky_icon}/></button>}
                            </div>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}

export default Meet