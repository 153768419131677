const header_bg = `${process.env.resourceUrl}images/pioneers/header_bg.webp`
const looking_1 = `${process.env.resourceUrl}images/pioneers/looking_1.webp`
const looking_2 = `${process.env.resourceUrl}images/pioneers/looking_2.webp`
const looking_3 = `${process.env.resourceUrl}images/pioneers/looking_3.webp`
const perks_svg1 = `${process.env.resourceUrl}images/pioneers/perks_1.svg`
const perks_svg2 = `${process.env.resourceUrl}images/pioneers/perks_2.svg`
const perks_svg3 = `${process.env.resourceUrl}images/pioneers/perks_3.svg`
const perks_svg4 = `${process.env.resourceUrl}images/pioneers/perks_4.svg`
const perks_svg5 = `${process.env.resourceUrl}images/pioneers/perks_5.svg`
const perks_svg6 = `${process.env.resourceUrl}images/pioneers/perks_6.svg`
const shouldbe_svg_1 = `${process.env.resourceUrl}images/pioneers/shouldbe_svg_1.svg`
const shouldbe_svg_2 = `${process.env.resourceUrl}images/pioneers/shouldbe_svg_2.svg`
const shouldbe_svg_3 = `${process.env.resourceUrl}images/pioneers/shouldbe_svg_3.svg`
const shouldbe_svg_4 = `${process.env.resourceUrl}images/pioneers/shouldbe_svg_4.svg`
const shouldbe_svg_5 = `${process.env.resourceUrl}images/pioneers/shouldbe_svg_5.svg`
const join_svg_1 = `${process.env.resourceUrl}images/pioneers/join_svg_1.svg`
const join_svg_2 = `${process.env.resourceUrl}images/pioneers/join_svg_2.svg`
const join_svg_3 = `${process.env.resourceUrl}images/pioneers/join_svg_3.svg`
const join_svg_4 = `${process.env.resourceUrl}images/pioneers/join_svg_4.svg`
const join_svg_5 = `${process.env.resourceUrl}images/pioneers/join_svg_5.svg`

const pioneers_meet_1 = `${process.env.resourceUrl}images/pioneers/pioneers_meet_1.jpg`
const pioneers_meet_2 = `${process.env.resourceUrl}images/pioneers/pioneers_meet_2.PNG`
const pioneers_meet_3 = `${process.env.resourceUrl}images/pioneers/pioneers_meet_3.JPEG`
const pioneers_meet_4 = `${process.env.resourceUrl}images/pioneers/pioneers_meet_4.JPEG`
const pioneers_meet_5 = `${process.env.resourceUrl}images/pioneers/pioneers_meet_5.png`
const pioneers_meet_6 = `${process.env.resourceUrl}images/pioneers/pioneers_meet_6.png`
const pioneers_meet_7 = `${process.env.resourceUrl}images/pioneers/pioneers_meet_7.jpeg`
const pioneers_meet_8 = `${process.env.resourceUrl}images/pioneers/pioneers_meet_8.png`
const pioneers_meet_9 = `${process.env.resourceUrl}images/pioneers/pioneers_meet_9.jpeg`

const yt_icon=`${process.env.resourceUrl}images/pioneers/yt_icon.svg`
const x_icon=`${process.env.resourceUrl}images/pioneers/x_icon.svg`
const reddit_icon=`${process.env.resourceUrl}images/pioneers/reddit_icon.svg`
const tiktok_icon=`${process.env.resourceUrl}images/pioneers/tiktok_icon.svg`
const bsky_icon=`${process.env.resourceUrl}images/pioneers/bsky_icon.svg`

export{
    yt_icon,
    x_icon,
    reddit_icon,
    tiktok_icon,
    bsky_icon,
    pioneers_meet_1,
    pioneers_meet_2,
    pioneers_meet_3,
    pioneers_meet_4,
    pioneers_meet_5,
    pioneers_meet_6,
    pioneers_meet_7,
    pioneers_meet_8,
    pioneers_meet_9,
    header_bg,
    looking_1,
    looking_2,
    looking_3,
    perks_svg1,
    perks_svg2,
    perks_svg3,
    perks_svg4,
    perks_svg5,
    perks_svg6,
    shouldbe_svg_1,
    shouldbe_svg_2,
    shouldbe_svg_3,
    shouldbe_svg_4,
    shouldbe_svg_5,
    join_svg_1,
    join_svg_2,
    join_svg_3,
    join_svg_4,
    join_svg_5
}